import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import '../assets/scss/main.scss'
const ogImage = require('../images/nichicoma-square.png')
const Layout = ({ children, location }) => {
  let content

  if (location && location.pathname === '/') {
    content = <div>{children}</div>
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>{children}</div>
      </div>
    )
  }

  if (typeof window !== 'undefined') {
    require('smooth-scroll')('a[href*="#"]', { speed: 200 })
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content:
                  'システム開発から要件のご相談まで、全てお任せください。 | 日常に、ちょっと楽しいヒトコマを',
              },
              {
                property: 'og:title',
                content: 'ニチコマ合同会社',
              },
              {
                property: 'og:description',
                content:
                  'システム開発から要件のご相談まで、全てお任せください。 | 日常に、ちょっと楽しいヒトコマを',
              },
              {
                property: 'og:image',
                content: `https://nichicoma.netlify.app${ogImage}`,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                property: 'twitter:card',
                content: 'summary',
              },
            ]}
          >
            <html lang="ja" />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
